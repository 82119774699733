.dvc{
    width: 98vw;
}
.footerCall{
    display: none;
}
.navCaroImg {
    min-height: 338.84px;
    min-width: auto;
    opacity: 0.15;
}
.overlay img{
    background-size: cover;
    width: 500px;
    height: 400px;
    margin-top: -110px;
}
.homeOverlayMobile{
    display: none;
    padding-top: 3rem!important;
}
.homeOverlayTab{
    display: none;
}
.carousel-inner {
max-height: 345px;
}
.navcarousel{
    
    z-index: 99;
}
.carou{
    margin-top: -56px;
}
.thecaro{
    background-color: transparent !important;
}

@media only screen and (min-width: 992px) {
    .homeNavLi a {
        margin-right: 35px;
        margin-left: 35px;
    }
    .homeNavUl{
        padding-right: 60px;
        margin-right: 45px;
    }
}
@media only screen and (max-width: 1440px) {
    .values-border{
        height: 268px;
    }
}
@media only screen and (max-width: 990px) {
    .values-border{
        height: 200px;
    }
}
@media only screen and (min-width: 1155px) {
    .homeNavUl{
        font-size: 13px;
    }
}
.homeNavLi a {
    color: #111111;
    font-family: "Monstserrat";
}
.Logo{
    /* padding-top: -10px; */
    margin-top: -10px;
}
.Logo-link{
    margin-left: 180px;
}
.overlay { 
    color:#1B4DE0;
    position:absolute;
    z-index:12;
    top:24%;
    left:0;
    width:100%;
    text-align:center;
    font-family: "Monstserrat" !important;
}
.homeOverlayWeb .one{
    padding-left: 150px;
}
.homeOverlayWeb h1{
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 67px;
    text-align: left;
}
.homeOverlayWeb p{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 118.4%;
    letter-spacing: 0.007em;
    color: #2D2D2D;
    text-align: left;
    
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: #5d85fd !important;
}
.valuesOneText p {
   padding-top: 20px;
   font-family: "Monstserrat" !important;
}

.row-two{
    padding-top: 20px;
}
.values-text{
    padding-top: 50px;
    padding-bottom: 30px;
}
.values-img img{
    max-height: 200px;
}
.homeValuesSectionOne{
    background-repeat: repeat-y;
    background-attachment: fixed;
    padding-top: 30px;
}
.HomeValuesSectionTwo{
    padding-top: 45px;
    font-family: "Monstserrat" !important;
    padding-bottom: 100px;
}
.content h4{
    padding-top: 32px;
}
.content p{
    padding-top: 18px;
}
.section-two{
    padding-top: 65px;
}
@media only screen and (max-width: 767px) {
    .relevance{
        text-align: center !important;
        justify-content: center !important;
    }
    .inner-row{
        padding-left: 25% !important;
    }
    .pill-carou{
        padding-left: 34% !important;
    }
}
.relevance{
    padding-left: 60px !important;
}
.relevance h3 {
    color: rgba(27, 77, 224, 1);
}
.relevance p {
    padding-top: 10px;
}
.inner-row{
    padding-top: 22px;
}
.inner-row span {
    font-size: 14px;
    /* padding-top: -15px; */
}
.progressbarone{
    width: 98%
}
.progressbartwo{
    width: 100%
}
.progressbarfour{
    width: 95%
}
.readBlog{
    background-color: black;
    padding-top: 75px;
    font-family: "Monstserrat" !important;
}
.blog-p1{
    color: white;
}
.blog-p2{
    color: white;
}
.homeBlogImg{
    max-width: 1072px;
    max-height: 326px;
}

@media only screen and (max-width: 1260px) {
    .homeBlogImg{
        max-width: 600px;
    max-height: 326px;
    }
}

@media only screen and (max-width: 638px) {
    .homeBlogImg{
        max-width: 250px;
    max-height: 326px;
    }
}
@media only screen and (max-width: 217px) {
    .homeBlogImg{
        max-width: 150px;
        max-height: 326px;
    }
}
@media only screen and (max-width: 414px) {
    .overlay h1{
        font-size: 18px;
    }
}
/* @media only screen and (max-width: 820px) {
    .overlay h1{
        font-size: 25px;
    }
} */
.btnHomeBlog{
    color: white !important;
    /* margin-left: 30px; */
}
.workedFor{
    padding-top: 20px;
    font-family: "Monstserrat" !important;
}
.workedForCarousel{
    max-height: 100px;
    max-width: 303px;
    /* border-radius: 30% !important; */
}
.inner-carou img{
    /* border-radius: 30px !important; */
    max-height: 55px !important;
    max-width: 170px !important;    
}
.extra{
    padding-top: 10px !important;
}
.borderr{
    border: 1px solid rgb(215, 213, 213) !important;
    max-height: 89px !important;
    max-width: 255px !important;
    border-radius: 30px !important;
    box-shadow: -6px -3px 2px 0px rgba(0, 0, 0, 0.17);

}
.coddingStack{
    padding-top: 40px;
    font-family: "Monstserrat" !important;
}

@media only screen and (max-width: 767px) {
    .pill-carou{
        padding-top: 40px;
    }
}
@media only screen and (max-width: 576px) {
    .pill-carou{
        display: block;
        padding-left: 28% !important;
    }
}
@media only screen and (max-width: 390px) {
    .pill-carou{
        /* display: block; */
        padding-left: 15% !important;
    }
}
.stackBorder{
    /* position: relative; */
    max-width: 334px;
    max-height: 72px;
    background: rgba(241, 242, 245, 0.6);
    border: 2px solid rgb(241, 242, 245, 0.6) !important;
    display: flex;
    align-items: center;
    border-radius: 15px !important;
}

.stackImg{
    padding-left: 15px;
    
}
.img_div{
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 970px) {
    .coddingStack{
        display: none;
    }
}
.hire-border{
    border: 1px solid rgba(171, 171, 171, 0.6) !important;
    width: 127px !important;
    height: 100px;
    border-radius: 20px;
    display: flex;
}
.Hire{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right bottom;
    font-family: "Monstserrat" !important;
    padding-bottom: 150px;
    /* background-size: auto; */
    /* padding-top: 30px; */
}
.HireOurTalent p{
    size: 20px
}
.hire-btn{
    border: 1px solid rgba(171, 171, 171, 0.6) !important;
}
.hire-span{
    size: 10px
}

@media only screen and (max-width: 655px) {
    .hire-border{
        width: 84px !important;
        height: 60px;
        background-size: 80px 60px;
    }
}
@media only screen and (max-width: 1312px) {
    .hire-border{
        width: 100px !important;
        height: 80px;
        background-size: 95px 80px;
    }
}
@media only screen and (max-width: 1045px) {
    .hire-border{
        width: 90px !important;
        height: 70px;
        background-size: 85px 70px;
    }
}
@media only screen and (max-width: 930px) {
    .hire-border{
        width: 75px!important;
        height: 55px;
        background-size: 75px 55px;
    }
}
@media only screen and (max-width: 385px) {
    .hire-border{
        width: 55px!important;
        height: 35px;
        background-size: 55px 35px;
    }
}
.Footer{
    position: absolute;
    width: 98vw;
    height: auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: bottom;
    font-family: "Monstserrat" !important;
    box-sizing: border-box !important;
    /* padding-bottom: 325px; */
}
.FooterTop{
    padding-top: 60px;
}
/* .FooterBottom{
    position: relative;
    width: 1036px;
    height: 232px;
    left: 476px;
    background-position: bottom;
} */

.FooterBottom{
    border: 1px solid rgba(21, 138, 167, 0.6) !important;
    height: 232px;
    background-repeat: no-repeat;
    position: relative;
}

.FootSecOne p{ 
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    
    color: #FFFFFF;
}

.FooterNav ul{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 30px;
    color: white;
    list-style-type: none;
}
/* @media only screen and (max-width: 445px) {
    .Logo{
        display: block;
        margin-left: auto;
    }
} */
.contactsFooter{
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 164.4%;
    /* or 16px */
    
    
    color: #FFFFFF;
}
.socialsFooter{
    border: 1px solid rgba(136, 171, 182, 1) !important;
    width: 32px;
    height: 32px; 
    border-color: rgba(136, 171, 182, 1);
    background-color: rgba(136, 171, 182, 1);
    border-radius: 50%;
}
.SocialFoot{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2px;
}

.queryHeader{
    display: none;
}
.mobileHeader{
    display: none;
    background: linear-gradient(180deg, #1B4DE0 0%, #1B4DE0 69.23%, #16D2ED 100%) !important;
    
}
@media only screen and (max-width: 1161px) {
    .Footer{
        width: 1061px;
        /* display: none; */
    }

}
@media only screen and (max-width: 1021px) {
    .Footer{
        /* width: 1001px; */
        display: none;
    }
    .mobileHeader{
        display: block;
        width: 98vw;
        height: auto;
        position: absolute;
    }

}
@media only screen and (max-width: 1021px) {
    .FooterNav{
        /* width: 1001px; */
        display: none;
    }
}
@media only screen and (max-width: 1085px) {
    .nav-link{
        font-size: 13px !important;
    }
}
@media only screen and (max-width: 1119px) {
    .nav-link{
        font-size: 9px !important;
    }
}


@media only screen and (max-width: 991px) {
    .Logo-link{
        margin-left: 45px;
    }
    .homeNavUl{
        padding-bottom: 50px;
    }
}
.FooterTwo{
    border: 1px solid rgba(21, 138, 167, 0.6) !important;
    height: 232px;
    background-repeat: no-repeat;
    position: relative;
    ;
}
.mobileHeaderFont p{
    color: white;
    font-size: 13px;
    padding-top: 10px;
}
.mobileFooterNav ul{
    color: white;
    list-style-type: none;
    line-height: 40px;
    padding-top: 10px;
}

@media only screen and (max-width: 540px) {
    .copyright{
        display: none;
    }
    .footerCall{
        display: block;
        color: #FFFFFF;
    }
}
@media only screen and (max-width: 280px) {
    .overlay h1{
        font-size: 15px;
    }
}

/*Footer Query Start*/
@media only screen and (max-width: 3500px) {
    .Footer{
        background-size: 3500px;
        width: 98vw;
    }
    .FooterBottom{
        background-size: 2548px;
        width: 98vw;
    }
}

@media only screen and (max-width: 3343px) {
    .Footer{
        background-size: 3343px;
        width: 98vw;
    }
}
@media only screen and (max-width: 3202px) {
    .Footer{
        background-size: 3202px;
        width: 100vw;
    }
}
@media only screen and (max-width: 3100px) {
    .Footer{
        background-size: 3100px;
        width: 100vw;
    }
}
@media only screen and (max-width: 3000px) {
    .Footer{
        background-size: 3000px;
        width: 100vw;
    }
}
@media only screen and (max-width: 2870px) {
    .Footer{
        background-size: 2800px;
        width: 100vw;
    }
}
@media only screen and (max-width: 2750px) {
    .Footer{
        background-size: 2708px;
        width: 100vw;
    }
}

@media only screen and (max-width: 2708px) {
    .Footer{
        background-size: 2600px;
        width: 100vw;
    }
}

@media only screen and (max-width: 2697px) {
    .Footer{
        background-size: 2697px;
        width: 100vw;
    }
    .FooterBottom{
        background-size: 2548px;
        width: 100vw;
    }
}

@media only screen and (max-width: 2552px) {
    .Footer{
        background-size: 2532px;
        width: 100vw;
    }

}

@media only screen and (max-width: 2450px) {
    .Footer{
        background-size: 2400px;
        width: 100vw;
    }

}
@media only screen and (max-width: 2250px) {
    .Footer{
        background-size: 2200px;
        width: 100vw;
    }

}
@media only screen and (max-width: 2151px) {
    .Footer{
        background-size: 2100px;
        width: 98vw;
    }

}
@media only screen and (max-width: 1950px) {
    .Footer{
        background-size: 1900px;
        width: 98vw;
    }

}
@media only screen and (max-width: 1830px) {
    .Footer{
        background-size: 1800px;
        width: 98vw;
    }

}
@media only screen and (max-width: 1700px) {
    .Footer{
        background-size: 1700px;
        width: 98vw;
    }

}
@media only screen and (max-width: 1700px) {
    .Footer{
        background-size: 1700px;
        width: 98vw;
    }

}
@media only screen and (max-width: 1630px) {
    .Footer{
        background-size: 1600px;
        width: 98vw;
    }
}
@media only screen and (max-width: 1590px) {
    .Footer{
        background-size: 1590px;
        width: 98vw;
    }
}
@media only screen and (max-width: 1520px) {
    .Footer{
        background-size: 1500px;
        width: 98vw;
    }
}

@media only screen and (max-width: 1450px) {
    .Footer{
        background-size: 1430px;
        width: 98vw;
    }
}
@media only screen and (max-width: 1380px) {
    .Footer{
        background-size: 1380px;
        width: 98vw;
    }
}
@media only screen and (max-width: 1340px) {
    .Footer{
        background-size: 1300px;
        width: 98vw;
    }
}
@media only screen and (max-width: 1280px) {
    .Footer{
        background-size: 1280px;
        width: 98vw;
    }
}

@media only screen and (max-width: 1253px) {
    .Footer{
        background-size: 1240px;
        width: 98vw;
    }
}
@media only screen and (max-width: 1210px) {
    .Footer{
        background-size: 1188px;
        width: 98vw;
    }
}
@media only screen and (max-width: 1160px) {
    .Footer{
        background-size: 1130px;
        width: 98vw;
    }
}

@media only screen and (max-width: 1059px) {
    .Footer{
        background-size: 1010px;
        width: 98vw;
    }
}

/* Footer Query End*/

/*ABOUT STYLES */
.AboutNav{
    font-family: "Monstserrat" !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: auto;
}

.aboutPostContent{
    /* position: relative; */
    /* width: 100vw;*/
    height: 610px;

    /* margin-top: -70px; */
}
.home-link{
    color: white !important;
}
.leftContent{
    margin-left: 80px;
}
.leftContent h1{
    font-weight: 500;
    font-size: 50px;
    line-height: 134.4%;
    letter-spacing: 1.5px;
    color: #FFFFFF;
    /* margin-left: 80px; */
}
.leftContent p{
    font-style: normal;
    font-weight: 500;
    line-height: 133.9%;
    color: #FFFFFF;
    /* margin-left: 80px; */
    font-size: 20px;
}
.AboutLogoExtras{
    margin-left: -40px;
}
.AboutGetStarted{
    color: white !important;
    border: 1px solid rgb(215, 213, 213) !important;
    /* margin-left: 80px; */
}
.AboutGetStarted:hover{
    color: #1B4DE0 !important;
    border: 1px solid #10f3ff !important;
    
}
.ImgNav{
    border: 1px solid #3fa1f7 !important;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 457px;
    border-radius: 10px;
}
.CompanyProfile{
    background-color: white;
    font-family: "Monstserrat" !important;
    padding-left: 80px;
    padding-right: 80px;
}
.profileContent1 p{
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
}
.heading h3{
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
}
.profileContent2 p{
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    text-align: justify;
}

.SixDSection {
    /* width: 1514px; */
    /* height: 800px; */
    font-family: "Monstserrat" !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 96vw;
    height: auto;
    padding-top: 25px;
}
.first-row{
    margin-left: 80px;
}
.first-row h1{
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
    color: #FFFFFF;
}
.first-row p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #FFFFFF;
}
.sixDImg{
    max-width: 100%;
    max-height: 351px;
}
.second-row{
    margin-left: 60px;
}
.second-row h1{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.72px;
    color: #FFFFFF;
}
.second-row p{
    color: #FFFFFF;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.72px;
}
.third-row{
    margin-left: 60px;
    margin-right: 30px;
}
.third-row h1{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.72px;
    color: #FFFFFF;
}
.third-row p{
    color: #FFFFFF;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.72px;
}
.theRowSixD{
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-size: cover; */
    background-position: right bottom;
}

.Mission{
    font-family: "Monstserrat" !important;
    padding-top: 50px;
    background-color: #FFFFFF;
}
.Mission-left{
    padding-left: 80px;
}
.mission-left-img{
    width: 332px;
    height: 72.5px; 
    /* align-items: "center"; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.mission-left-img h2{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
}
.Mission-left p{
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}
.mission-right-img{
    border: 1px solid rgba(71, 187, 203, 0.96) !important;
    /* border-radius: 30px !important; */
    width: 97%;
    height: 465px;
    background-size: cover;
}
.Feedback{
    font-family: "Monstserrat" !important;
    background-color: #FFFFFF;
    margin-bottom: 40px;
}
.feedback-heading h2{
    font-weight: 500;
    font-size: 40px;
    line-height: 49px;
}
.feedback-img{
    width: 60px;
    height: 60px;   
    box-shadow: 1px 7px 5px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(130, 130, 130, 0.25) !important;
    border-radius: 50%;
    
}
.feedback-border{
    border: 1px solid #ffffff !important;
    width: 100%;
    height: 323px;
    background: #FFFFFF;
    box-shadow: 2px 7px 5px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    

    
}

.feedback-border-img{
    z-index:12;
    width: 60px;
    height: 60px;   
    box-shadow: 1px 7px 5px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(130, 130, 130, 0.25) !important;
    border-radius: 50%;
    margin-top: 100px;
}
.middle-man{
    height: 90px;
}
.first-man{
    margin-left: 40px;
}
.feedback-border-p1{
    font-weight: 400;
font-size: 16px;
line-height: 20px;
text-align: center;
}
.border-content h3{
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
}
.border-content p{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.7px;
}

.values-border{
    border: 2px solid rgba(71, 187, 203, 0.96) !important;
    border-radius: 30px !important;
    max-height: 268px;
    box-shadow: 1px 7px 5px rgba(0, 0, 0, 0.25);
}
.bulb_ptag{
    font-size: 15.5px;
    padding-left: 5px;
    padding-right: 5px;
}

@media only screen and (max-width: 1187px) {
    .values-border p{
        font-size: 13px;
        padding-top: 3px;
    }
    .values-border h4{
        font-size: 13px;
    }
}
.img-7{
    height: 49px;
    width: 49px;
}
.About-Profile-BG{
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-size: cover; */
    background-position: right;
}
@media only screen and (min-width: 1518px) {
    .home-link{
        color: black !important;
    }
    
}

@media only screen and (max-width: 1167px) {
    .offer-link{
        color: white !important;
    }
}

@media only screen and (max-width: 1084px) {
    .blog-link{
        color: white !important;
    }
}

@media only screen and (max-width: 1178px) {
    .leftContent p{
        font-size: 17px !important;
    }
    .leftContent h1{
        font-size: 40px !important;
        margin-top: 60px;
    }
}
@media only screen and (max-width: 991px) {
    .ImgNav{
        max-height: 342px;
        max-width: 80%;
        /* width: 80%; */
        margin-top: 0px !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
        
    }
    .ImgNavContainer{
        padding-top: 0px !important;
        margin-top: -60px !important;
    }
    .leftContent{
        margin-top: -50px !important;
        padding-top: 0px !important;
    }
    .CompanyProfile{
        margin-top: 110px;
    }
}
@media only screen and (max-width: 575px) {
    .CompanyProfile{
        margin-top: 150px !important;
    }
}
@media only screen and (max-width: 517px) {
    .CompanyProfile{
        margin-top: 210px !important;
    }
}
@media only screen and (max-width: 414px) {
    .leftContent p{
        font-size: 15px !important;
    }
    .leftContent h1{
        font-size: 35px !important;
        margin-top: 60px;
    }
    .leftContent{
        margin-top: -100px !important;
        padding-top: 0px !important;
    }
    .ImgNav{
        max-height: 250px;
    }
    .CompanyProfile{
        margin-top: 150px !important;
    }
}
@media only screen and (max-width: 767px) {
    .SixDSection{
        text-align: center !important;
    }
}
@media only screen and (max-width: 991px) {
    .Mission{
        text-align: center !important;
        display: flex;
        /* align-items: center; */
        justify-content: center;
    }
    .mission-left-img{
        margin-left: 15%;
    }
    .mission-left-img p{
        padding-left: -30px;
    }
    .mission-right-img{
        margin-left: 20px;
        height: 335px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 991px) {
    .mission-left-img{
        margin-left: -20px;
    }
    .profileContent1 p{
        font-size: 17px !important;
        line-height: 25px !important;
    }
    .profileContent2 p{
        font-size: 17px !important;
        line-height: 25px !important;
    }
    .first-row h1{
        font-size: 30px !important;
        line-height: 40px !important;
    }
    .first-row p{
        font-size: 18px !important;
        line-height: 25px;
    }
    .first-row{
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .second-row{
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
    .third-row{
        margin-left: 20px !important;
        margin-right: 20px !important;
        /* text-align: center !important; */
    }
    .leftContent h1 {
        letter-spacing: 1.5px;
        margin-left: 80px;
    }
    .leftContent p {
        margin-left: 80px;
        margin-right: 20px;
    }
    
}
.mobileView{
    display: none;
}
@media only screen and (max-width: 1021px){
    .normalView{
        display: none;
    }
    .mobileView{
        display: block;
    }
    .border-content{
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media only screen and (max-width: 991px) {
    .mission-left-img{
        margin-left: 20%;
    }
}
@media only screen and (max-width: 585px) {
    .mission-left-img{
        margin-left: 12%;
    }
}
@media only screen and (max-width: 502px) {
    .mission-left-img{
        margin-left: 5%;
    }
}
@media only screen and (max-width: 432px) {
    .mission-left-img{
        margin-left: 0px;
    }
}

@media only screen and (max-width: 450px) {
    .CompanyProfile{
        margin-top: 280px !important;
    }
}
@media only screen and (max-width: 414px) {
    .CompanyProfile{
        margin-top: 150px !important;
    }
}
@media only screen and (max-width: 413px) {
    .feedback-border-p1{
        font-size: 14px !important;
        line-height: 17px !important;
    }
}
.offerNav{
    font-family: "Monstserrat" !important;
    padding-bottom: 3rem!important;
}
.OfferGetStartedOffer{
    color: (27, 77, 224, 1) !important;
    border: 1px solid rgba(27, 77, 224, 1) !important;
}
.OfferGetStartedOffer:hover{
    color: white !important;
}
.offerLeftContent{
    padding-left: 80px;
    padding-top: 3rem!important;
}
.offer-p1{
    font-style: normal;
    font-weight: 600;
    font-size: 45px;
    line-height: 49px;
    color: #1B4DE0;
}

@media only screen and (max-width: 414px) {
    .offer-p1{
        font-size: 35px;
    }
}
.offerLeftContent h2{
    font-weight: 200;
    font-size: 26px;
    line-height: 135%;
    letter-spacing: 13.5px;
    color: #1B4DE0;
}
.offerLeftContent h3{
    color: #16D2ED;
}
.offer-p2{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 116.4%;
    letter-spacing: 0.007em;
    color: #2D2D2D; 
    padding-top: 20px;
}
.offerRightImgOne{
    padding-top: 70px;
}
.offerRightImgThree{
    margin-left: 127px;
}
.WorkWeDone{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right bottom;
    font-family: "Monstserrat" !important;
    background-color: rgb(240, 239, 239);
}
.WorkDoneSectionOne{
    padding-left: 80px;
}
.WorkDoneSectionOne h2{
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 49px;
}
.WorkDoneSectionOne p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.7px;
}


/* Work done carousel style */
.swiper-slide {
    background-position: center !important;
    background-size: cover !important;
    width: 300px !important;
    height: max-content !important;
    /* overflow:scroll */

}
@media only screen and (min-width: 1955px) {
    .swiper-slide {

        overflow:hidden
    
    }
}

@media (max-width: 768px) {
.swiper-slide {
    width: 250px !important;
    height: auto !important;
}
}

.swiper-slide-prev img{
    height: 300px !important;
}
.swiper-slide-prev{
    transition-duration: 0ms !important;
    transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(30deg) scale(1) !important;
    z-index: 0 !important;
}
.swiper-slide-next{
    transition-duration: 0ms !important;
    transform: translate3d(0px, 0px, -100px) rotateX(0deg) rotateY(-40deg) scale(1) !important;
    z-index: 0 !important;
}
.swiper-slide img {
    display: block !important;
    width: 364px !important;
    height: 590px !important;
}

.container {
    position: relative !important;
    width: 65% !important;
    height: auto !important;
    background-color: rgb(240, 239, 239) !important;
    display: block !important;
}

.container .swiper-container {
    width: 75% !important;
    position: absolute !important;
    left: 50% !important;
    bottom: -16vw !important;
    -webkit-transform: translateX(-50%)!important;
    transform: translateX(-50%) !important;
    z-index: 2 !important;
    padding-bottom: 3vw !important;
}

@media (max-width: 768px) {
    .container .swiper-container {
        bottom: -58vw !important;
        padding-bottom: 8vw !important;
    }
}

.swiper-pagination-fraction {
    bottom: 0 !important;
}

.swiper-pagination-bullet {
    width: 25px !important;
    height: 5px !important;
    border-radius: 5px !important;
}  
.swiper{
    height: 650px !important;
    margin-top: 70px;
    width: 50vw;
}

.swiper-button-prev:after{
    padding-left: 70px;
}
.swiper-button-prev:after{
    display: none;
}
.swiper-button-next:after{
    /* padding-left: 70px; */
    display: none;
}
.someWeDone{
    color: #1B4DE0
}

.CoWorkingSpace{
    font-family: "Monstserrat" !important;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 98.35vw;
    height: 496px;
}
.CoWorkingSpaceBorder{
    width: 472px;
    height: 333px;
    background: linear-gradient(180deg, #1B4DE0 0%, #16D2ED 100%);
    border-radius: 5px;
    left: 159px;
    position: relative;
    bottom: -140px;
    background-size: cover;
}
.CoworkGetStarted{
    color: white !important;
    border: 1px solid rgb(215, 213, 213) !important;
}
.CoworkGetStarted:hover{
    color: #22f2fd!important;
    border: 1px solid #1B4DE0 !important;
}
.CoWorkingSpaceBorder h3{
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 133.4%;
    letter-spacing: 0.02em;
    color: #FFFFFF;
}
.CoWorkingSpaceBorder p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.72px;
    color: #FFFFFF;
}
.CoWorkingContent{
    margin-left: 50px;
    padding-top: 3rem!important;
}
.Services{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 70%;
    font-family: "Monstserrat" !important;
    margin-left: 40px;
    margin-right: 30px;
}
.this{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position:left bottom;
}
.Services h2{
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 33px;
    letter-spacing: 0.72px;
}
.service-box-one{
    border: 2px solid rgba(71, 187, 203, 0.96) !important;
    width: auto;
    height: 280px;
    background: #FFFFFF;
    box-shadow: -5px 0px 9px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}   
.service-box-content{
    padding-left: 40px;
    padding-top: 3rem;
}
.service-box-content h3{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    font-family: "Space_Grotesk" !important;
    
}
.service-box-content p{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    font-family: "Space_Grotesk" !important;
    
}
.OfferBlogPosts{
    font-family: "Monstserrat" !important;
    padding-left: 40px;
    padding-right: 30px;
    background-color: rgb(240, 239, 239);

}
.FooterNav li{
    font-size: 12px;
}
@media only screen and (max-width: 1094px) {
    .FooterNav li{
        font-size: 9px;
    }
}
.offerHeadingContent h2{
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
}
.offerHeadingContent p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.72px;
}
.blog-span h4{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}
.blog-span p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #686868;
    padding-left: 20px;
}
.blog-span{
    margin-left: 25px;
}
.offerBlogContent h1{
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 29px;
    margin-left: 25px;
}
.offerBlogContent-p{
    margin-left: 25px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
}
.OfferMobile{
    display: none;
}
.offerBlogImg{
    background-size: cover;
}
@media only screen and (max-width: 1392px) {
    .offerRightImgSeven{
        display: none;
    }
}
@media only screen and (max-width: 1188px) {
    .offerDesktop{
        display: none;
    }
    .OfferMobile{
        display: block;
    }
}

@media only screen and (min-width: 1987px) {
    .offerDesktop{
        display: none;
    }
    .OfferMobile{
        display: block;
        margin-top: 0 !important;  
    }
    .offerRightImgTwoMobile{
        height: 100vh !important;
    }
}

.OfferMobile{
    margin-top: 155px;
}
@media only screen and (max-width: 767px) {
    .OfferMobile{
        margin-top: 50px;
    }
}

.offerRightImgTwoMobile{
    background-size: cover;
    height:auto;
}

@media only screen and (max-width: 775px) {
    .CoWorkingSpaceBorder{
        width: 387px;
        height: 314px;
        left: 0px;
        margin-top: 250px;
    }
    .Services{
        margin-top: 208px;
    }
}
@media only screen and (max-width: 465px) {
    .offerBlogImg{
        width: 300px;
        height: 232px;
    }
}
@media only screen and (max-width: 380px) {
    .offerBlogImg{
        width: 250px;
        height: 195px;
    }
}
@media only screen and (max-width: 455px) {
    .service-box-content{
        padding-top: 30px !important;
        padding-left: 30px;
    }
}
@media only screen and (max-width: 408px) {
    .service-box-content{
        padding-top: 10px !important;
    }
}
@media only screen and (max-width: 393px) {
    .CoWorkingSpaceBorder{
        width: 300px;
        height: 300px;
    }
    .CoWorkingContent{
        padding-top: 2.3rem!important;  
    }   
    .CoWorkingContent h3{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 133.4%;
        letter-spacing: 0.02em;
    }
    .CoWorkingContent p{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.72px;
    }
}
.homeOverlayTab h1{
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 67px;
    /* text-align: center; */
    letter-spacing: 0.02em;
}
.homeOverlayTab p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 116.4%;
    /* text-align: center; */
    letter-spacing: 0.007em;
    color: #2D2D2D;
}
@media only screen and (max-width: 1224px) {
    .homeNavUl a{
        font-size: 10px;
    }
}
@media only screen and (max-width: 1076px) {
    .homeNavUl a{
        font-size: 7px !important;
    }
    
}
@media only screen and (max-width: 1027px) {
    .homeOverlayWeb h1{
        font-size: 35px;
    }
    .homeOverlayWeb p{
        font-size: 13px;
    }
}
@media only screen and (max-width: 820px) {
    .overlay h1{
        font-size: 25px;
        padding-bottom: 5px;
    }
}

/* @media only screen and (max-width: 575px) {
    .homeOverlayTab{
        display: none !important;
    }
    .homeOverlayMobile{
        display: block !important;
    }
} */
.footerNav{
    color: white;
    text-decoration: none; 
}
@media only screen and (max-width: 1253px) {
    .homeOverlayWeb .one{
        padding-left: 80px;
    }
}
@media only screen and (max-width: 1113px) {
    .homeOverlayWeb .one{
        padding-left: 40px;
    }
}
@media only screen and (max-width: 1033px) {
    .homeOverlayWeb .one{
        padding-left: 30px;
    }
}
.three h1 {
    text-align: center;
}

.three p {
    text-align: center;
}
.three{
    display: none;
}
@media only screen and (max-width: 991px) {
    .one{
        display: none;
    }
    .two{
        display: none;
    }
    .three{
        display: block;
    }
    
}

.blogImgNav{
    width: auto !important;
    height: auto !important;
    opacity: 0.35 !important;
    margin-top: -60px !important;
    background-size: cover;
    
}
.blogImgNavBg{
    width: auto !important;
    height: auto !important;
    margin-top: -300px !important;
    z-index:1;
    background-size: cover;
}
.theOverlay h1{
    /* z-index:1; */
    position: absolute;
    left: 41%;
    top: 25%;
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 61px;
    text-align: center;
    color: #01317A;

}
.BlogName{
    font-family: "Monstserrat" !important;
}

.theBlogContent h1{
    font-style: normal;
    font-weight: bolder;
    font-size: 24px;
    line-height: 29px;
    margin-left: 25px;
}
.theBlogContent-p{
    margin-left: 25px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
}
.theBlogImg{
    background-size: cover;
}
@media only screen and (max-width: 465px) {
    .theBlogImg{
        width: 300px;
        height: 232px;
    }
}
@media only screen and (max-width: 380px) {
    .theBlogImg{
        width: 250px;
        height: 195px;
    }
}
.BlogContent{
    margin-top: 120px;
    margin-left: 40px;
}
.categories-h3{
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
}
.theBlogContentSection{
    padding-top: 95px;
}
.categoriesContainer{
    position: relative;
    text-align: center;
}
.blog-overlay{
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.categoriesContainer h1{
    font-family: "Monstserrat" !important;
    font-style: normal;
    font-weight: bolder;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

@media only screen and (min-width: 1624px) {
    .blog-wrap{
        margin-left: 40px;
    }
}
@media only screen and (min-width: 1850px) {
    .blog-wrap{
        margin-left: 75px;
    }
}
@media only screen and (min-width: 2000px) {
    .blog-wrap{
        margin-left: 100px;
    }
}

@media only screen and (min-width: 2220px) {
    .blog-wrap{
        margin-left: 137px;
    }
}

@media only screen and (min-width: 2380px) {
    .blog-wrap{
        margin-left: 165px;
    }
}
@media only screen and (min-width: 2555px) {
    .blog-wrap{
        margin-left: 195px;
    }
}
@media only screen and (min-width: 2755px) {
    .blog-wrap{
        margin-left: 225px;
    }
}
@media only screen and (min-width: 3000px) {
    .blog-wrap{
        margin-left: 265px;
    }
}
@media only screen and (min-width: 3300px) {
    .blog-wrap{
        margin-left: 315px;
    }
}
@media only screen and (min-width: 3600px) {
    .blog-wrap{
        margin-left: 365px;
    }
}

@media only screen and (max-width: 1281px) {
    .blogImgNavBg{
        margin-top: -280px !important;
    }
}

@media only screen and (max-width: 1278px) {
    .blogImgNavBg{
        margin-top: -280px !important;
    }
}
@media only screen and (max-width: 1180px) {
    .blogImgNavBg{
        margin-top: -260px !important;
    }
    .theOverlay h1 {
        font-size:40px;
    }
}
@media only screen and (max-width: 1087px) {
    .blogImgNavBg{
        margin-top: -240px !important;
    }
    .theOverlay h1 {
        font-size:35px;
        top: 21%;
    }
}
@media only screen and (max-width: 986px) {
    .blogImgNavBg{
        margin-top: -220px !important;
    }
    .theOverlay h1 {
        font-size:35px;
        top: 21%;
    }
    .blog-wrap{
        margin-left: 245px;
    }
}

@media only screen and (max-width: 949px) {
    .blog-wrap{
        margin-left: 225px;
    }
}
@media only screen and (max-width: 991px) {
    .theBlogContentSection{
        padding-top: 0px;
    }
    .BlogContent{
        margin-top: 100px;
    }
    .blogImgNavBg{
        margin-top: -220px !important;
    }
    .blog-wrap{
        margin-left: 250px;
    }
}


@media only screen and (max-width: 868px) {
    .blogImgNavBg{
        margin-top: -200px !important;
    }
    .theOverlay h1 {
        font-size:30px;
        top: 11%;
    }
    .blog-wrap{
        margin-left: 180px;
    }
    
    .theBlogContentSection{
        padding-top: 0px;
    }
    .BlogContent{
        margin-top: 100px;
    }
}

@media only screen and (max-width: 785px) {
    .blogImgNavBg{
        margin-top: -165px !important;
    }
    .theOverlay h1 {
        font-size:24px;
        top: 5%;
    }
    .blog-wrap{
        margin-left: 150px;
    }
    .theBlogContentSection{
        padding-top: 0px;
    }
    .BlogContent{
        margin-top: 100px;
    }
}
@media only screen and (max-width: 781px) {
    .blogImgNavBg{
        margin-top: -180px !important;
    }
}
@media only screen and (max-width: 700px) {
    .blogImgNavBg{
        margin-top: -165px !important;
    }
    .theOverlay h1 {
        font-size:24px;
        top: 5%;
    }
    .blog-wrap{
        margin-left: 105px;
    }
    .theBlogContentSection{
        padding-top: 0px;
    }
    .BlogContent{
        margin-top: 100px;
    }
}
@media only screen and (max-width: 620px) {
    .blogImgNavBg{
        margin-top: -145px !important;
    }
    .theOverlay h1 {
        font-size:20px;
        top: 1%;
    }
    .blog-wrap{
        margin-left: 55px;
    }
    .theBlogContentSection{
        padding-top: 0px;
    }
    .BlogContent{
        margin-top: 100px;
    }
}
@media only screen and (max-width: 530px) {
    .blogImgNavBg{
        margin-top: -125px !important;
    }
    .theOverlay h1 {
        font-size:20px;
        top: 0%;
    }
    .blog-wrap{
        margin-left: 20px;
    }
    .theBlogContentSection{
        padding-top: 0px;
    }
    .BlogContent{
        margin-top: 100px;
    }
}
@media only screen and (max-width: 430px) {
    .blogImgNavBg{
        margin-top: -105px !important;
    }
    .theOverlay h1 {
        font-size:20px;
        top: -20%;
    }
    .blog-wrap{
        margin-left: 5px;
    }
    .theBlogContentSection{
        padding-top: 0px;
    }
    .BlogContent{
        margin-top: 100px;
    }
}
.contactUsContainer{
    background-color: #EFF2FC !important;
    font-family: "Monstserrat" !important;
    
}
.ContactUsContent{
    background-color: #EFF2FC !important;
}
.BgContactUsContent{
    font-family: "Monstserrat" !important;
    margin-left: 14%;
    margin-top: 30px;
}
.contactBorderBlue{
    width: 552px;
    height: 348px;
    background: #1845C9;
    margin-top: 100px;
    margin-left: 30px;
    background-size: contain;
    border-radius: 30px;
    
}
.ContactUsContent{
    font-family: "Monstserrat" !important;
}
.contactBorderContent h1{
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
}
.contactBorderContent{
    padding-top: 30px;
    padding-left: 60px;
}
@media only screen and (max-width: 1132px) {
    .contactBorderContent{
        padding-left: 0px;
    }
}
.contactBorderContent p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
@media only screen and (max-width: 655px) {
    .mobileHeader p{
        font-size: 9px !important;
    }
    .mobileFooterNav li{
        font-size: 12px !important;
    }
}
.homeOverlayMobile p{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 116.4%;
    text-align: center;
    letter-spacing: 0.007em;
    font-family: "Monstserrat" !important;
    color: #2D2D2D;
}
@media only screen and (max-width: 524px) {
    .homeOverlayWeb p{
        font-size: 10px !important;
    }
}
@media only screen and (max-width: 399px){
    .overlay h1 {
        font-size: 15px;
        padding-bottom: 10px;
    }
    .overlay p{
        font-size: 7px !important;
    }
    .navCaroImg {
        min-height: 200.84px;
    }
    .homeOverlayWeb {
        padding-top: 30px !important;
    }
    .homeOverlayWeb h1{
        line-height: 0px;
    }
}
.borderStuff{
    border: 1px solid white !important;
    width: 80vw;
    border-radius: 30px !important;
    background-color: white;
}
.contactOverlap{
    margin-left: -150px !important;
}
.contactUsForRow{
    margin-left: 23% !important;
    padding-top: 60px;
}
.contactUsFormHeading h1{
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 37px;
    color: #1B4DE0;
}
.contactUsFormHeading p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #686868;
    
}
.contactUsForm input{
    margin-bottom: 45px;
    background: #F1F1F1;
    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
}
.contactUsForm textarea{
    margin-bottom: 45px;
    background: #F1F1F1;
    
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
}

.otherBorderView{
    display: none;
    margin-left: 23% !important;
    padding-top: 60px;
}

@media only screen and (max-width: 1266px){
    .contactOverlap{
        display: none;
    }
    .contactUsForRow{
        display: none;
    }
    .otherBorderView{
        display: block;
        margin-left: 13% !important;
    }
    .BgContactUsContent {
        margin-left: 10%;
    }
    .contactBorderBlue {
        margin-left: 0px;
        margin-bottom: 25px;
    }
}

@media only screen and (max-width: 414px){
    .BgContactUsContent {
        margin-left: 6%;
    }
}

.ContactUsAddress{
    padding-bottom: 1.5rem!important;
    padding-top: 1.5rem!important;
}
.ContactUsMail{
    padding-bottom: 1.5rem!important;
}
.ContactUsTel{
    padding-bottom: 1.5rem!important;
}
@media only screen and (max-width: 746px){
    .contactBorderContent h1{
        /* font-size: 23px; */
        padding-bottom: 18px;
    }
    .contactBorderContent p{
        /* font-size: 13px; */
    }
    .ContactUsAddress{
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }
    .ContactUsMail{
        padding-bottom: 0px !important;
    }
    .ContactUsTel{
        padding-bottom: 0px !important;
    }
}


@media only screen and (max-width: 535px){
    .contactUsFormHeading p{
        font-size: 12px !important;
    }
    .contactUsFormHeading h1{
        font-size: 28px !important;
    }

}
@media only screen and (max-width: 440px){
    .contactBorderContent{
        padding-left: 5px !important;
    }
    
}

@media only screen and (max-width: 512px){
    .contactBorderContent h1{
        font-size: 19px;
        padding-bottom: 18px;
    }
    .contactBorderContent p{
        font-size: 11px;
    }
    .ContactUsAddress img{
        /* display: none; */
    }
}
@media only screen and (max-width: 400px){
    .contactUsFormHeading p{
        font-size: 10px !important;
    }
    .contactUsFormHeading h1{
        font-size: 24px !important;
    }
}

@media only screen and (max-width: 302px){
    .contactUsFormHeading p{
        font-size: 8px !important;
    }
    .contactUsFormHeading h1{
        font-size: 21px !important;
    }
}
@media only screen and (max-width: 285px){
    .contactBorderBlue{
        display: none !important;
    }
    .contactSubmit{
        margin-bottom: 30px !important;
        /* font-size: 6px !important; */
    }
}

.BlogOverlay img{
    background-size: cover;
    width: 500px;
    height: 400px;
    margin-top: -110px;
}
.BlogOverlay { 
    color:#1B4DE0;
    position:relative;
    z-index:12;
    top:24%;
    left:0;
    width:100%;
    text-align:center;
    font-family: "Monstserrat" !important;
}

@media only screen and (max-width: 414px) {
    .BlogOverlay h1{
        font-size: 18px;
    }
}
@media only screen and (max-width: 820px) {
    .BlogOverlay h1{
        font-size: 25px;
    }
}
@media only screen and (max-width: 280px) {
    .BlogOverlay h1{
        font-size: 15px;
    }
}
@media only screen and (max-width: 399px){
    .BlogOverlay h1 {
        font-size: 15px;
    }
    .homeOverlayMobile p{
        font-size: 7px !important;
    }
    .navCaroImg {
        min-height: 200.84px;
    }
    .homeOverlayMobile {
        padding-top: 20px !important;
    }
}

@media only screen and (max-width: 480px) {
    .container{
        display: none !important;
    }
}

.none-img h1 {
    text-align: center;
}
.none-img p {
    text-align: center;
}

.btn-xl {
    padding: 10px 20px !important;
    font-size: 20px !important;
    border-radius: 10px;
}
/* .third-caro{
    margin-top: -30px;
} */
@media only screen and (max-width: 680px) {
    .relevance {
        padding-left: 30px !important;
    }
    .pill-carou {
        padding-left: 30% !important;
    }
}
@media only screen and (max-width: 490px) {
    .relevance {
        padding-left: 15px !important;
    }
    .pill-carou {
        padding-left: 22% !important;
    }
}
@media only screen and (max-width: 400px) {

    .pill-carou {
        padding-left: 20% !important;
    }
}
@media only screen and (max-width: 820px) {

    .leftContent h1 {
        margin-left: 0px;
        margin-right: 20px;
    }
    .leftContent p {
        margin-left: 0px;
    }
    .CompanyProfile {
        padding-left: 20px;
        padding-right: 0px;
    }
    .leftContent {
        margin-left: 30px;
        margin-right: 20px;
    }
    .Mission-left {
        text-align: left !important;
        padding-left: 20px !important;
    }
    .feedback-content{
        margin-left: 10px !important;
    }
    .mission-left-img {
        margin-left: 0px;
    }
    .CompanyProfile{
        margin-top: 140px !important;
    }
    .mission-right-img{
        margin-top: 40px !important;
    
    }
    .offerLeftContent{
        padding-left: 10px;
        padding-top: 0px !important;
    }
    .WorkDoneSectionOne {
        padding-left: 10px;
    }
    .CoWorkingContent {
        margin-left: 10px;
    }
    .offerNav{
        padding-bottom: 0px !important;
    }
    .WorkDoneSectionOne{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .offerPostContent{
        margin-bottom: 30px;
        
    }
    .BlogContent {
        margin-left: 0px;
    }
}
@media only screen and (max-width: 414px) {
    .ContactUsSocials{
        padding-bottom: 20px !important;
    }
}
@media only screen and (max-width: 414px) {
    .contactBorderBlue {
        height: 370px;
    }
    .otherBorderView{
        margin-left: 15% !important;
    }
}
.p-nbr{
    display: none;
}
@media only screen and (max-width: 633px) {
    .p-br{
        display: none !important;
    }
    .p-nbr{
        display: block !important;
    }
}